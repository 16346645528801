import React, { useMemo } from 'react';
import classNames from 'classnames';
import Format from 'components/template-designer/types/format.type';
import Template, { DesignerSettings, View } from 'components/template-designer/types/template.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import FrameTypeHelpers from 'components/template-designer/helpers/frame-type.helpers';
import { DEFAULT_ZOOM_LEVEL } from 'components/template-designer/constants';
import { Scene } from 'components/template-designer/scene/scene';
import CanvasFormat from './format';
import '../styles/preview-format.scss';

interface Props {
    /**
     * The class name to add to the component.
     */
    className?: string;
    /**
     * The format to show.
     */
    format: Format;
    /**
     * The frame type to show.
     */
    frameType: View['frameType'];
    /**
     * The custom layer properties to show.
     */
    customLayerProperties?: Template['layerProperties'];
    /**
     * The size of the format container.
     */
    size: {
        width: number;
        height: number;
    };
    /**
     * Whether to show the layers.
     * Default is true.
     */
    showLayers?: boolean;
}

/**
 * Shows a preview format.
 * For the frame type selector and formats scale dialog.
 */
const PreviewFormat = ({ className, format, frameType, customLayerProperties, size, showLayers = true }: Props) => {
    // Calculate the scaling factor based on the container dimensions.
    const containerWidth = size.width; // Width of the format container.
    const containerHeight = size.height; // Height of the format container.
    const scaleX = containerWidth / (format?.width ?? 0);
    const scaleY = containerHeight / (format?.height ?? 0);

    // Use the minimum scaling factor to ensure the object fits within the container.
    const scale = Math.min(scaleX, scaleY);

    // Apply CSS styles to the format container to scale it down and fit it within the container.
    const formatContainerStyles = {
        width: `${format?.width ?? 0}px`,
        height: `${format?.height ?? 0}px`,
        transform: `scale(${scale})`,
        transformOrigin: 'center'
    };

    // Get the frame duration for the frame type.
    const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);

    /**
     * Get the keyframes for the format.
     */
    const keyframes = useMemo(() => {
        const layerProperties = customLayerProperties ? customLayerProperties : getTemplateData<Template['layerProperties']>('layerProperties');
        const layers = getTemplateData<Template['layers']>('layers');
        return CanvasHelpers.generateSceneKeyframes(
            layerProperties,
            layers[frameType],
            frameType,
            [format.key],
            frameDuration,
            true,
            getTemplateData<DesignerSettings['enableAnimations']>('designerSettings.enableAnimations')
        );
    }, [customLayerProperties, frameType, format, frameDuration]);

    return (
        <div
            className={classNames('template-designer__preview-format-format', className)}
            style={{ minWidth: size.width, maxWidth: size.width, minHeight: size.height, maxHeight: size.height }}>
            <div style={formatContainerStyles} key={frameType + ' ' + format.key}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Scene keyframes={keyframes} time={frameDuration} fillMode="forwards" iterationCount="infinite" autoplay={false}>
                    <CanvasFormat
                        preview
                        zoomLevel={DEFAULT_ZOOM_LEVEL}
                        format={format}
                        showLayers={showLayers}
                        frameType={frameType}
                        customLayerProperties={customLayerProperties}
                        showBorder
                    />
                </Scene>
            </div>
        </div>
    );
};

export default PreviewFormat;
